.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  color: white;
}

.App-header {
  font-size: calc(10px + 2vmin);
  text-align: center;
}

.App-header h1 {
  font-size: 4rem;
  margin: 0;
}

.App-header p {
  font-size: 1.5rem;
  margin-top: 1rem;
}